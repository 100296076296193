import React, {PureComponent, ReactNode} from "react";
import adac from "../../../public/assets/images/adac-discount.svg";

type AdacSectionProps = {

};

export class AdacSection extends PureComponent<AdacSectionProps> {
    render(): ReactNode {
        const {
        } = this.props

        return (
            <div className="adacSection px-6 md:px-12  gap-x-24  mb-20">
                <div className="grid grid-cols-2 px-6 bg-gray-100 py-5 lg:py-20 rounded-lg">
                    <div className={'w-40 lg:w-auto adacSection__ImageBloc col-span-2 lg:col-span-1 flex justify-start lg:justify-center py-10 lg:py-5'}>
                        <picture>
                            <source media="(max-width: 768px)" srcSet={`${adac.src}`}/>
                            <source media="(min-width: 769px)" srcSet={`${adac.src}`}/>
                            <img
                                loading={'lazy'}
                                src={adac.src}
                                alt="ADAC Logo"
                            />
                        </picture>
                    </div>

                    <div className={'adacSection__textBlock col-span-2 lg:col-span-1 lg:w-2/3 mx-auto'}>
                        <h2 className={`text-3xl lg:text-4xl leading-9 font-semibold lg:font-normal md:leading-10 font-serif text-gray-900 pb-5`}>Als ADAC Mitglied bei der Werkstattbuchung sparen</h2>
                        <p className={'text-lg leading-6 md:text-lg md:leading-7 font-serif text-gray-500 pb-8'}>Erhalte 3 % Cashback auf deine Werkstattrechnung als ADAC Mitglied.</p>
                        <a
                            href="https://lp.repareo.de/adac-vorteilswelt"
                            title={'Als ADAC Mitglied bei repareo buchen'}
                            target="_blank"
                            rel={'noreferrer'}
                            className={'bg-white rounded-md p-2 text-gray-900 no-underline shadow w-auto inline-block mb-8 lg:mb-0 px-5 py-3 mx-auto'}>
                            Als ADAC Mitglied fortfahren
                        </a>
                    </div>
                </div>
            </div>
        )}
}
